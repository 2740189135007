import React from "react";
import Editor from "./MarkdownEditor/Editor";

function MarkdownEditor() {
  return (
    <div>
      <Editor />
    </div>
  );
}

export default MarkdownEditor;
